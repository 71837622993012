<template>
  <div>
    <div>
      <span class="d-block text-xl-h4 text-lg-h4 text-md-h4 text-sm-h5 text-h6 font-weight-bold black--text">
        Data Sekolah
      </span>
      <small class="text-subtitle-2">Halaman data sekolah</small>
    </div>
    <div class="my-6">
      <v-row class="justify-space-between">
        <v-col
          md="4"
          class=""
        >
          <div>
            <v-text-field
              v-model="search"
              label="Cari Sekolah"
              outlined
              dense
              class="bg-white"
              @change="searchHandler($event)"
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          md="8"
          class="d-flex justify-end"
        >
          <v-col
            md="5"
            class="py-0"
          >
            <div class="d-xl-flex d-lg-flex d-md-flex">
              <span class="d-block mb-2 my-md-2 my-lg-2 my-xl-2 me-2">Tingkat:</span>
              <v-autocomplete
                label="Semua"
                dense
                :items="levels"
                item-text="name"
                item-value="uuid"
                outlined
                @change="filterByLevel($event)"
              ></v-autocomplete>
            </div>
          </v-col>
          <v-col
            md="4"
            class="py-0"
          >
            <div class="d-xl-flex d-lg-flex d-md-flex">
              <span class="d-block mb-2 my-md-2 my-lg-2 my-xl-2 me-2">Urutkan:</span>
              <v-autocomplete
                label="Semua"
                dense
                :items="sorts"
                outlined
                @change="filterBySort($event)"
              ></v-autocomplete>
            </div>
          </v-col>
        </v-col>
      </v-row>
      <div v-if="!isLoadingCard">
        <v-row class="match-height">
          <v-col
            v-for="school in schools"
            :key="school.name"
            md="4"
          >
            <router-link
              :to="{ name: 'school-data-hob-detail', params: {uuid: school.uuid} }"
              class="text-decoration-none"
            >
              <v-card min-height="">
                <v-img
                  height="150"
                  :src="school.image"
                ></v-img>
                <v-card-text class="py-4">
                  <h3 class="font-weight-bold mb-2">
                    {{ school.name }}
                  </h3>
                  <div class="d-flex mb-2">
                    <div class="d-flex align-center mb-2">
                      <v-img
                        :src="icons.iconLocation"
                        width="20"
                      ></v-img> <span class="ml-4">{{ school.place.address }}</span>
                    </div>
                  </div>
                  <div class="d-flex mb-2">
                    <div class="d-flex align-center mb-2">
                      <v-img
                        :src="icons.iconClip"
                        width="20"
                      ></v-img> <span class="ml-4">link</span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </router-link>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row
          class="mb-5"
        >
          <v-col
            v-for="i,index in 3"
            :key="index"
            md="4"
          >
            <v-skeleton-loader
              type="image"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </div>
      <div
        v-if="isEmpty"
        class="d-flex justify-center"
      >
        <div

          class="pa-4 text-center"
        >
          <v-img
            src="../../../assets/images/vector/empty.svg"
            class="mx-8 my-7"
          ></v-img>
          <p class="my-7">
            Belum ada data sekolah
          </p>
        </div>
      </div>
      <div class="mt-6">
        <v-pagination
          v-model="page"
          :length="totalPages"
          total-visible="7"
          @change="paginationHandler"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import iconClip from '../../../assets/icons/clip.svg'
import iconLocation from '../../../assets/icons/location.svg'

export default {
  name: 'SchoolData',
  data() {
    return {
      search: '',
      sorts: ['Semua', 'Terbaru', 'Terlama'],
      sort: '',
      levels: ['Semua', 'SD', 'SMP', 'SMA/SMK'],
      level: '',
      icons: {
        iconLocation,
        iconClip,
      },
      service: 'school',
      page: 1,
      isEmpty: false,
      schools: [],
      totalPages: 0,
      regencies_uuid: '',
      isLoadingCard: true,
    }
  },
  watch: {
    page: {
      handler() {
        this.isLoadingCard = true
        this.get({ page: this.page })
      },
    },
    search: {
      handler() {
        this.isLoadingCard = true
        this.get({ search: this.search })
      },
    },
    level: {
      handler() {
        this.isLoadingCard = true
        this.get({ jenjang: this.level })
      },
    },
    sort: {
      handler() {
        this.isLoadingCard = true
        this.get({ sort: this.sort })
      },
    },
  },
  async mounted() {
    await this.getRegenciesUuid()
    this.get()
  },
  methods: {
    async get(params = {}) {
      await this.$services.ApiServices.list(this.service, {
        ...params,
        regencies_uuid: this.regencies_uuid,
        per_page: '6',
      }).then(
        ({ data }) => {
          this.schools = data.data
          this.isEmpty = false
          if (this.schools.length < 1) {
            this.isEmpty = true
          }
          this.isLoadingCard = false
          this.totalPages = data.meta.last_page
        },
        err => {
          console.error(err)
        },
      )
    },
    getRegenciesUuid() {
      this.regencies_uuid = JSON.parse(localStorage.getItem('user')).user.details[0].regencies[0].uuid
    },
    async paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    filterBySort(data) {
      if (data === 'Terbaru') {
        this.sort = 'asc'
      } else if (data === 'Terlama') {
        this.sort = 'desc'
      } else if (data === 'Semua') {
        this.sort = ''
      }
    },
    filterByLevel(data) {
      if (data === 'SD') {
        this.level = 'SD'
      } else if (data === 'SMP') {
        this.level = 'SMP'
      } else if (data === 'SMA/SMK') {
        this.level = 'SMK'
      } else if (data === 'Semua') {
        this.level = ''
      }
    },
    searchHandler(data) {
      this.search = data
    },
  },
}
</script>

<style>
</style>
